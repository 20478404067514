<template>
  <component :is="`dv-border-box-${border}`" :dur="0" class="container l-numboard">
    <div class="caption">{{data.name}}</div>
    <div class="value" :style="`font-size:${220 * factor}%;bottom:${18 * factor*factor}px;color:${data.em?'#33ffaa':''}`">{{data.value}} 
    <span class="unit" v-if="data.unit" :style="`font-size:${50 * factor}%;`">{{data.unit}}</span></div>
    
  </component>
</template>

<script>
  import responsableMixin from '@/mixins/responsable'
  export default {
    props:{
      data:{
        type:Object,
        default:()=>{}
      },
    },
    mixins:[responsableMixin],
    computed:{
      factor(){
        let h = this.h
        return (h > 100?1:(h/100))
      },
      border(){
        return this.data.border || 13
      },
      config(){
        return {
          number:[this.data.value],
          toFixed:this.data.toFixed,
          textAlign:"right",
          content:'{nt}'
        }
      }
    }
  }
</script>

<style lang="less">
.l-numboard{
  .caption{
    position: absolute;
    left:15px;
    top:15px;
    color:#3ef;
    font-size:14px;
  }

  .value{
    position: absolute;
    bottom:18px;
    right:20px;
    color:#3ef;
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    font-size:220%;
    font-family: impact;
  }

  .unit{
      font-size:16px;
      font-weight: bold;
      color:#aaa;
    
  }
}
</style>