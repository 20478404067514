<template>
  <dv-border-box-12 class="container ldv-chart">
        <div class="title">{{data.name}}</div>
        <div class="flex-wrap flex-center" v-if="!pause" style="background:#ffffff11;margin:0 10px;">
          <dv-active-ring-chart :config="config" style="width:250px;height:250px;position:relative;top:-45px;" />
        </div>
  </dv-border-box-12>
</template>

<script>
  export default {
     props:{
      data:{
        type:Object,
        default:()=>{}
      },
      pause:{
        type:Boolean
      }
    },
    data(){
      return {
        config:{
          lineWidth: 12,
          showOriginValue: true,
           data: this.data.raw || []
        }
      }
    }
  }
</script>
<style lang="less">
.ldv-chart{
  position: relative;
  .title{
    padding:10px 15px;
    color:#3ef;
  }
}
</style>