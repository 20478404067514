import {API} from '@/plugins/api'
const o = {
  namespaced:true
}

o.state = {
  session:{},
  projects:[],
  uid:null,
  pid:null
}

o.getters = {
  uid(state){
    return state.uid
  },
  pid(state){
    return state.pid
  },
  project(state){
    return state.projects.find(v=>v.id == state.pid) || {}
  },
  projects(state){
    return state.projects
  }
}

o.actions = {
  list_projects({commit,getters}){
    return new Promise((resolve,reject)=>{
      let projects = getters.projects
      if(projects.length > 0)
        resolve()
      return API.get('zzl/projects').then(res=>{
        commit('save_projects',res.data.data)
        resolve()
      }).catch(reject)
    })
  }
}

o.mutations = {
  save_projects(state,projects){
    state.projects = projects || []
  },
  save_uid(state,uid){
    console.log('save_uid',uid)
    state.uid = uid
    API.defaults.headers.zzlid = uid
  },
  save_pid(state,pid){
    console.log('save-pid:',pid)
    state.pid = pid
  }

}




export default o
