<template>
  <dv-border-box-8 :dur="0" class="container ldv-chart">
    <div class="title">{{data.name}}</div>
    <div class="flex-wrap flex-center" style="margin:0 10px;margin-bottom:10px;position:relative;overflow:hidden;background:#ffffff11;height:calc(100% - 50px);">
      <dv-charts :option="option" style="width:calc(20px + 100%);height:calc(40px + 100%);position:relative;left:10px;" />
    </div>
  </dv-border-box-8>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    
      option() {
        return {
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLine: {
            style: {
              stroke: "#3ef"
            },
          },
          splitLine: {
      show: false
    },
          axisTick: {
            show: false
          },
          axisLabel: {
            style: { 
          fill: '#3ef'
        }
          }
        },
        yAxis: {
          data: 'value',
          name:"万元",
          min:0,
          nameTextStyle:{
            fill:"#3af"
          },
          axisTick: {
            show: false
          },splitLine: {
            show: false
          },
          axisLine: {
            style: {
              stroke: "#3ef"
            },
          },
          axisLabel: {

            style: {
          fill: '#3af'
        }
          }
        },
        grid:{
          show:false,
        },
        series: [
          {
            data: this.data.raw || [],
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }
        ]      }
    }
  }
}
</script>
<style lang="less">
.ldv-chart {
  position: relative;
  .title {
    padding: 10px 15px;
    color: #3ef;
  }
}
</style>