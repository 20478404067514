<template>
   <dv-border-box-10 class="container ldv-plist">
    <div class="title">{{data.name}}</div>
    <div ref="container" style="position:absolute;left:10px;top:35px;right:10px;bottom:10px;width:auto !important;display:flex;flex-wrap:wrap;align-content:flex-start;">

      <template v-for="(p) in current_items">
        <div :key="p.id" class="l-project"  :class="{'l-project-ani':p.hide}" :style="`height:${p.hide?0:itemHeight}px;`">
          <img v-lazy="p.avatar" />
          <div class="flex-col align-start" style="flex-shrink:1;position:relative;padding-right:100px;">
            <div class="l-project-title"><span style="margin-right:5px;background:#3af;border-radius:5px;display:inline-block;width:28px;height:18px;line-height:18px;font-size:10px;font-weight:bold;color:#fff;margin-bottom:2px;text-align:center;" :style="`${p.index>30?'background:green':''}`">{{p.index}}</span>{{p.name}}</div>
            
          </div>
          <div class="l-project-sub">{{p.type}}</div>
          <div class="flex-col align-end" style="position:absolute;right:10px;bottom:10px;flex-shrink:0;">
            <div class="l-amount"> {{p.amount}}</div>
            <div class="l-date" :style="`color:${p.finished?'':'#3fd'};`">人均 {{p.hrvalue}}</div>
          </div>
        </div>
      </template>
    </div>
  </dv-border-box-10>
</template>

<script>
  export default {
   computed:{
      projects(){
        return this.data.raw || []
      }
    },
     props:{
      data:{
        type:Object,
        default:()=>{}
      }
    },
    data(){
      return {
        current_items:[],
        cur_page:0,
        page_size:5,
        paused:false,
        itemHeight:40,
      }
    },
    mounted(){
      this.paused = false
      setTimeout(()=>{
        
      this.getPage()
      this.interval = setInterval( this.getPage,10000)
      },1000)

      this.$nextTick(()=>{
        this.itemHeight = this.$refs.container.clientHeight / this.page_size
      })
    },
    methods:{
      getPage(){
        if(this.paused)
          return
        
        if(this.cur_page >= Math.ceil(this.projects.length - this.page_size + 1))
          this.cur_page = 1 - this.page_size
        if(this.current_items.length == 0){
          this.current_items = this.projects.slice(this.cur_page,this.cur_page + this.page_size).map(v=>({...v,hide:false}))
        }else{
          this.current_items[0].hide = true
          this.current_items.push({...this.projects[this.cur_page+this.page_size],hide:false})
          setTimeout(()=>{
            this.current_items.splice(0,1)
            
          },1000)
          this.cur_page++
        }
      },
      pause(){
        this.paused = true
      },
      restart(){
        this.paused = false
      }
    },
  }
</script>


<style lang="less">
.ldv-plist{
  position: relative;
  .title{
    padding:10px 15px;
    color:#3ef;
  }

.l-project:nth-child(even){
  margin-right:0;
}
.l-project-ani{
    transition:all 0.5s;
  }
  .l-project{
    >div{
      position: relative;
      z-index:1;
    }

    img{
      left:0;
      right:0;
      top:0;
      bottom:0;
      position: absolute;
      opacity: 0.3;
      width:100%;
    }
    background:#ffffff11;
    height:80px;
    width:100%;
    overflow:hidden;
    position: relative;
    overflow: hidden;
    margin-bottom:2px;
    display: flex;
    flex-shrink:0;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap:nowrap !important;
    
    .l-project-title{
      font-size:15px;
      max-height:40px;
      width:100%;
      overflow: hidden;
      text-overflow:ellipsis;
    color:rgb(250, 250, 250);
    }

    .l-project-sub{
      
      color:rgb(32, 255, 207);
      font-size:10px;
      
      position: absolute;
      left:10px;
      bottom:10px;
    }
    .l-amount{
      color:rgb(0, 213, 255);
      font-weight: bold;
    }

    .l-date{
      color:#aaa;
      font-size:10px;
      margin-top:2px;
    }
  }



  
}
</style>