<template>
  <div class="container flex-line align-stretch" style="overflow:hidden;background:#112233;box-shadow:0px 1px 1px 1px #334466aa">
    <div class="item" :class="value==i?'item-active':''" v-for="(m,i) in menus" :key="m.key" @click="value=i;$emit('input',i);$emit('on-change',i)">
      <i :class="`gzicon gzi-${m.icon}`" style="margin-right:10px;font-size:20px;font-weight:normal;position:relative;color:#33aaffbb" />{{m.name}}
    </div>
  </div>
</template>

<script>
  export default {
    props:['value'],
    computed:{
      menus(){
        return [{
          name:'经营情况',
          key:'operation',
          icon:'pm2'

        },{
          name:'项目分布',
  key:'project',
   icon:'xiangmu'
        },{
          name:'收费情况',
  key:'bill',
  icon:"money"
        },
  //       {
  //         name:'人员投入',
  // key:'hr',
  // icon:'supervisor'
  //       },
        
  //       {
  //         name:'事业部',
  // key:'dep',
  // icon:'organization'
  //       },
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
.item{
  transition:all 0.3s ease-out;
  flex-grow:1;
  border-right:1px solid #346;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#33beff66;
  
  padding:10px 0;
  width:10%; 
   border-bottom:2px solid #ffffff00;
   cursor:pointer;
}

.item:hover{
  
  background:#33eeff33;
  border-bottom:2px solid #33eeff;
   color:#29abfb;
}


.item-active,
.item-active:hover{
  background:linear-gradient(to bottom,#ffffff00,#bfdae536);
  border-color:#ffffff00;
  color:#33aaff;
}
</style>