import {
  mapGetters
} from 'vuex'
export default {
  data() {
    return {
      x: 0,
      y: 0,
      w: 1,
      h: 1
    }
  },
  // computed:{
  //   ...mapGetters('session',['screen','breakpoint'])
  // },
  props: ['ws', 'hs'],
  created() {
    this.$bus.$on('resize', () => {

      this.calcLayout()
      if (this.onResize) {
        this.onResize({
          w: this.w,
          h: this.h
        })
      }

    })
  },

  activated() {
    this.calcLayout()
  },
  methods: {
    calcLayout() {

      if (!this.$el || !this.$el.getBoundingClientRect)
        return
      let rect = this.$el.getBoundingClientRect()
      this.x = rect.x
      this.y = rect.y
      this.w = rect.width
      this.h = rect.height
    }
  }
}