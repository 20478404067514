<template>
  <dv-border-box-8 class="container ldv-list">
    <div class="title">{{data.name}}</div>
    <dv-scroll-ranking-board :config="config" style="position:absolute;left:10px;top:35px;right:20px;bottom:10px;width:auto !important;"  />
  </dv-border-box-8>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>{}
      }
    },
    computed:{
      config(){
        return {
          waitTime:5000,
            data: [
              {
                name: '周口',
                value: 55.3
              },
              {
                name: '南阳',
                value: 120
              },
              {
                name: '西峡',
                value: 78
              },
              {
                name: '驻马店',
                value: 66
              },
              {
                name: '新乡',
                value: 80
              },
              {
                name: '信阳',
                value: 45
              },
              {
                name: '漯河',
                value: 29
              }
            ],
            carousel: 'page'
                  }
      }
    }
  }
</script>

<style lang="less">
.ldv-list{
  position: relative;
  .title{
    padding:10px 15px;
    color:#3ef;
  }
}
</style>