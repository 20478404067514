import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
Vue.use(Vuex)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/cm'
    },
    {
       path: "/cm",
         component: resolve => require(['@pages/index'], resolve),
         children: [{
             path: "/cm/operation",
             component: resolve => require(['@pages/cm/operation'], resolve)
           },
           {
             path: "/cm/project",
             component: resolve => require(['@pages/cm/project'], resolve)
           },
           {
             path: "/cm/hrs",
             component: resolve => require(['@pages/cm/hrs'], resolve)
           },
           {
             path: "/cm/bill",
             component: resolve => require(['@pages/cm/bill'], resolve)
           },
           {
             path: "/cm/dep",
             component: resolve => require(['@pages/cm/dep'], resolve)
           },
         ]

    },
    {
      path:'/403',
        component: resolve => require(['@pages/403'], resolve)
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})


export default router
