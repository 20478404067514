
<style lang="less">
html,body{
  height:100% !important;
  width:100% !important;
  position: relative !important;
}
</style>

<template>
  <transition name="fade">
    <template v-if="initing">
      <div class="l-loading">
        <div style="font-size:10px">{{error || "系统初始化中"}}</div>
      <div style="font-size:30px">若本页面停留过长，建议更换浏览器进行访问 </div>
      <div><a href="http://zzlatm.gicp.net:10000/public/upload/20190916/20190916_5d7f2f83da6e8.exe">Google Chrome</a></div>
      
      </div>
    </template>
    <template v-else>
      <div style="height:100%;width:100%;position:relative;">
      <router-view />
     </div>
    </template>
    
  </transition>
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    name: 'App',
    data(){
      return {
        initing:false,
        error:""
      }
    },
    computed:{
      uid(){
        return this.$route.query.uid
      },
      project(){
        return this.$route.query.pid
      }
    },
    watch:{
      $route:{
        handler(v){
          if(this.$route.query){
            this.$store.commit('zzl/save_uid',this.$route.query.uid)
            this.$store.commit('zzl/save_pid',this.$route.query.pid)
          }
        },
        immediate:true
      }
    },
    metaInfo: {
      title: '首页',
      titleTemplate: '%s | 高专',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  }
</script>

<style lang="less">
  // @import "../public/css/index.css";
  // @import "../public/css/transitions.less";
  // @import "../public/css/styles2.less";
  @import "../public/css/styles.less";
  @import '~animate.css';
  .l-loading{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:#346;
    color:#fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
</style>


