<template>
  <div class="container l-ndv-caption">
    <div>{{data.name}}</div>
    <dv-decoration-10 style="width:80%;height:5px;margin:5px 0;min-height:5px;" />
    <div style="font-size:14px;color:#33ffbbaa">{{data.sub}}</div>
    
  </div>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>{}
      }
    }
  }
</script>

<style lang="less">
.l-ndv-caption{
  font-size:29px;
  font-weight:bold;
  color:#3ef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-shadow: 0 0 15px #0980ff97;
}
</style>