<template>
  <div class="flex-wrap flex-center" style="font-size:16px;color:#3ef">
    <dv-decoration-11 style="width:500px;height:50px;">经营状况（2023）</dv-decoration-11>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>