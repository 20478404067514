<template>
  <div class="container l-time">
    <div style="font-family:Impact;letter-spacing: 2px;">{{time}} </div>
    <div style="font-size:15px;color:#999">{{date}}</div>
    <div style="font-size:15px;">{{week}}</div>
    <div class="flex-col flex-center" style="width:60px;height:80px;position:absolute;right:150px;font-size:18px;cursor:pointer;border-right:2px solid #33aaff33;padding-right:10px;" @click="RouteTo('https://a.inbgz.com/core/cm')">
      <i class="gzicon gzi-nbgz" style="font-size:30px;color:#3ef;text-shadow:none;font-weight:normal;"  />
      <div style="color:#3ef;font-size:12px;margin-top:10px;">iNBGZ</div>
    </div>
    <!-- <div class="flex-col flex-center" style="width:60px;height:80px;position:absolute;right:230px;font-size:18px;cursor:pointer;border-right:2px solid #33aaff33;border-left:2px solid #33aaff33;padding:0 10px;" @click="toggleFull">
      <i class="gzicon gzi-QR-code1" style="font-size:30px;color:#3ef;text-shadow:none;font-weight:normal;"  />
      <div style="color:#3ef;font-size:12px;margin-top:10px;">全屏</div>
    </div> -->
    
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props:{
      data:{
        type:Object,
        default:()=>{}
      }
    },
    data(){
      return {
        time:moment().format("HH:mm:ss"),
        date:moment().format("YYYY年MM月DD日"),
        week:moment().format("第w周"),
      }
    },
    mounted(){
      if(this.timer)
        clearInterval(this.timer)
      this.timer = setInterval(()=>{
        this.time = moment().format("HH:mm:ss")
      },1000)
    },
    destroyed(){
      if(this.timer)
        clearInterval(this.timer)
    },
    methods:{
      toggleFull(e) {
        console.log('toggle')
        //获取全屏的元素
        if (!document.fullscreenElement) {
          //进入页面全屏
          document.documentElement.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            //退出全屏
            document.exitFullscreen();
          }
        }
    },
    }
  }
</script>

<style lang="less">
.l-time{
  font-size:35px;
  font-weight:bold;
  color:rgb(10, 217, 240);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-shadow: 0 0 15px #04d5ff67;
}
</style>