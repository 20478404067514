<template>
  <div class="container">
      <div style="font-size:16px;color:#3ef;position:absolute;left:10px;top:10px;right:10px;">
        <div style="padding:5px 0;border-bottom:1px solid #33aaff">
        2023年度合同余额
        </div>
         <div style="background:#33aaff33;margin-top:10px;padding:0px 10px;padding-bottom:10px;">
        <div class="flex-wrap flex-between">
          <div class="num-board">
            <div class="num-board-label" style="">合同余额</div>
            <div class="num-board-value flex-wrap align-end">{{current_contract_amount}}<div class="num-board-unit">万元</div></div>
          </div>

          <div class="num-board" style="margin-left:40px">
            <div class="num-board-label" style="">历史未到账</div>
            <div class="num-board-value flex-wrap align-end">{{untransferred_amount}}<div class="num-board-unit">万元</div></div>
          </div>
          <div class="num-board" style="margin-left:40px">
            <div class="num-board-label" style="">历史回款率</div>
            <div class="num-board-value flex-wrap align-end">{{untransferred_percent}}<div class="num-board-unit">%</div></div>
          </div>
        </div>
         <div class="flex-wrap flex-between">
          <div class="num-board">
            <div class="num-board-label" style="">2023年末合同余额</div>
            <div class="num-board-value flex-wrap align-end">{{end_year_contract_amount}}<div class="num-board-unit">万元</div></div>
          </div>

          
        </div>
        </div>


      </div>
  </div>
</template>

<script>
  
import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters('board',['end_year_contract_amount','untransferred_percent','untransferred_amount','current_contract_amount'])
    }
  }
</script>

<style lang="less" scoped>
.num-board{
  display:flex;
  flex-direction: column;
  .num-board-label{
    font-size:12px;color:#fff;margin-top:10px;
  }

  .num-board-value{
    font-family:impact;font-size:30px;
    .num-board-unit{
      margin-left:5px;font-size:14px;
    }
  }
}
</style>