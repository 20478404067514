<template>
  <dv-border-box-12 class="container ldv-chart">
        <div class="title">{{data.name}}</div>
        <div></div>
  </dv-border-box-12>
</template>

<script>
  export default {
     props:{
      data:{
        type:Object,
        default:()=>{}
      }
    },
    data(){
      return {
        config:{
           data: [{
      name: '南阳',
      value: 167
    },
    {
      name: '周口',
      value: 67
    },
    {
      name: '漯河',
      value: 123
    },
    {
      name: '郑州',
      value: 55
    },
    {
      name: '西峡',
      value: 98
    },
  ],
  unit: 'ml'
        }
      }
    }
  }
</script>
<style lang="less">
.ldv-chart{
  position: relative;
  .title{
    padding:10px 15px;
    color:#3ef;
  }
}
</style>