<template>
  <div class="container" style="padding:0 5px;width:calc(100% - 10px)">
      <div style="font-size:16px;color:#3ef;position:absolute;left:10px;top:10px;bottom:10px;right:10px;">
       
        <div style="padding:5px 0;border-bottom:1px solid #33aaff">
        2023年度收费计划完成情况
        </div>
         <div style="background:#ffffff11;margin-top:10px;padding:0px 10px;padding-bottom:10px;">
        <div class="flex-wrap flex-between">
          <div class="num-board">
            <div class="num-board-label" style="">全年计划收费总额</div>
            <div class="num-board-value flex-wrap align-end">{{config.year_bill_plan_amount}}<div class="num-board-unit">万元</div></div>
          </div>

          <div class="num-board" style="margin-left:40px">
            <div class="num-board-label" style="">全年已完成收费总额</div>
            <div class="num-board-value flex-wrap align-end">{{config.year_billed_amount}}<div class="num-board-unit">万元</div></div>
          </div>
          <div class="num-board" style="margin-left:40px">
            <div class="num-board-label" style="">已完成占比</div>
            <div class="num-board-value flex-wrap align-end"  style="color:#33ffaa">{{config.year_bill_plan_percent}}<div class="num-board-unit">%</div></div>
          </div>
        </div>
        
      </div>
        <div style="margin-top:10px;font-size:12px;color:#fff;width:100%;height:calc(100% - 100px);position:relative;">
          2023年收费计划按月分布
          <div style="background:#ffffff11;height:calc(100% - 40px);position:relative;border-radius:5px;">
          <div class="flex-wrap" style="font-size:12px;color:#3af;border-bottom:1px dashed #3af;margin-top:10px;padding:5px 0;">
            <div style="width:20%;text-align:center;">
            月份
            </div>
            <div  style="width:30%;text-align:center;">
            计划额
            </div>
            <div style="width:30%;text-align:center;">
            开票额
            </div>
            <div style="width:20%;text-align:center;">
            完成比例
            </div>
          </div>
          <div class="flex-col flex-line align-stretch" style="overflow:hidden;position:relative;height:calc(100% - 30px)">
          <template v-for="(m,i) in month_bill_plans">
          <div class="flex-wrap" style="font-size:20px;color:#3ef;border-bottom:1px dashed #3af;flex-grow:1;height:8%;flex-shrink:1;" :key="m.id" :style="i<=moment().month()?`border-right:2px solid #33ffaa`:''">
             <div class="flex-wrap flex-center" style="width:20%;text-align:center;font-size:12px;color:#fff;">
            <div style="background:#33aaff22;padding:2px 5px;width:60px;">{{m.name}}</div>
            </div>
            <div  style="width:30%;font-family:arial;font-size:16px;text-align:right">
            {{(m.plan_amount / 10000).toFixed(1)}}<span style="font-size:12px;margin-left:5px;margin-right:20px;"><span style="color:#ffffffaa">万元</span></span>
            </div>
            <div  style="width:30%;font-family:arial;font-size:16px;text-align:right" :style="m.actual_amount?'color:#3fa':'color:#ffffffaa'">
            {{(m.actual_amount / 10000).toFixed(1)}}<span style="font-size:12px;margin-left:5px;margin-right:20px;"><span style="color:#ffffffaa">万元</span></span>
            </div>
            <div  style="width:20%;font-family:arial;font-size:16px;text-align:right" :style="m.percent >= 90?'color:#3fa':(m.percent < 1?'color:#ffffffaa':'')">
            {{!isNaN(m.percent)?m.percent:'-'}}<span style="font-size:12px;margin-left:5px;margin-right:20px;"><span style="color:#ffffffaa">%</span></span>
            </div>
          </div>
          </template>
          
          </div>
        </div>
        </div>
        
       <dv-decoration-2 style="margin-top:0px;" />

      </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters('board',['config','loading','bill_plan_amount','bill_amount','bill_percent','transfer_percent','untransferred_amount','transfer_amount','month_bill_plans'])
    }
  }
</script>

<style lang="less" scoped>
.num-board{
  display:flex;
  flex-direction: column;
  min-width:30%;
  .num-board-label{
    font-size:12px;color:#fff;margin-top:10px;
  }

  .num-board-value{
    font-family:Impact;font-size:30px;
    .num-board-unit{
      margin-left:5px;font-size:14px;
    }
  }
}
</style>