// BASE SETTING to VUE
const bus = require('./bus')
// Imports
import Vue from 'vue'
import api from './api'
import moment from 'moment'
import UTIL from '@/utils/index'
import dataV from '@jiaminghi/data-view'
import VueLazyLoad from 'vue-lazyload'
// Setup PuzzleVerification plugins
Vue.use(dataV)
Vue.prototype.moment = moment
Vue.use(VueLazyLoad,{
  loading:require('@assets/image.png'),
  error: require('@assets/image.png'),
})
Vue.prototype.Download = function (url) {
  let a = document.createElement("a")
  a.href = url
  a.download = "download"
  a.target = "blank"
  document.body.appendChild(a)
  a.click()
  a.remove()
}

Vue.prototype.$util = UTIL


Vue.prototype.$bus = bus
Vue.use(api)



Vue.prototype.RouteTo = function (path, newtab = false) {
  if (window && window.event && window.event.ctrlKey)
    newtab = true
  if (!path)
    return

  // route to given url
  if (newtab) {
    if (!path.includes('http')) {
      let routerUrl = this.$router.resolve({
        path
      });
      window.open(routerUrl.href, "_blank");
    } else {
      window.open(path, "_blank");
    }
  } else {
    if (!path.includes('http')) {

      if (this.$route.path != path)
        this.$router.push(path)
    } else {
      window.location.href = path
    }
  }
}

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
const requireComponentPanel = require.context('@/components/panels', true, /\.vue$/)

for (const file of requireComponentPanel.keys()) {
  const componentConfig = requireComponentPanel(file)

  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  Vue.component(`Panel${componentName}`, componentConfig.default || componentConfig)
}


function getBlob(url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
}

/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    var body = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = "none";
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
function download(url, filename) {
  getBlob(url, function (blob) {
    saveAs(blob, filename);
  });
}

Vue.prototype.SaveAs = saveAs
Vue.prototype.DownloadWithName = download

Vue.directive('transfer', {
  inserted: el => {
    el.parentElement.removeChild(el)
    document.body.appendChild(el)

  }
})


Vue.config.productionTip = false

export default Vue
