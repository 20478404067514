<template>
  <dv-border-box-8 :dur="0" class="container ldv-chart" >
    <div class="title flex-wrap flex-between">{{data.name}} <div>{{total}}</div></div>
    <div class="flex-wrap flex-center" style="margin:0 10px;margin-bottom:10px;position:relative;overflow:hidden;background:#ffffff11;height:calc(100% - 40px);">
      <dv-charts :option="option" style="width:calc(20px + 100%);height:calc(50px + 100%);position:relative;top:-10px;" />
    </div>
  </dv-border-box-8>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
      total(){
        if(Array.isArray(this.data.raw))
          return this.data.raw.reduce((acc,item)=>acc+item,0)
        else
          return ''
      },
      option() {
        return {
        xAxis: {
          data: ['%0', '10%','20%','30%', '40%','50%', '60%','70%', '80%','90%', '100%'],
          axisLine: {
            style: {
              stroke: "#3ef"
            },
          },
          splitLine: {
      show: false
    },
          axisTick: {
            show: false
          },
          axisLabel: {
            style: { 
          fill: '#3ef'
        }
          }
        },
        yAxis: {
          data: 'value',
          name:"",
          min:0,
          nameTextStyle:{
            fill:"#3af"
          },
          axisTick: {
            show: false
          },splitLine: {
            show: false
          },
          axisLine: {
            style: {
              stroke: "#3ef"
            },
          },
          axisLabel: {
            
            style: {
          fill: '#3af'
        }
          }
        },
        grid:{
          show:false,
        },
        series: [
          {
            data: this.data.raw || [],
            label:{
                show:true,
                position:'top',
                textStyle:{
                  fontSize:'7px',
                  color:'#3ef'
                },
                formater:'{c}'
            },
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }
        ]      }
    }
  }
}
</script>
<style lang="less">
.ldv-chart {
  position: relative;
  .title {
    padding: 10px 15px;
    color: #3ef;
  }
}
</style>