import {
  API
} from '@/plugins/api'
import {
  debounce
} from 'lodash'
import UTIL from '@/utils'
import moment from 'moment'
const state = {
  config: {},
  loading: false,
}
const types = ['全过程咨询', '项目管理', '造价咨询', '市政监理', '房建监理', 'BIM咨询', '其他']

const model = {
  'bill_plan_amount': 0,
  'bill_amount': 0,
  'bill_percent': 0,
  'transfer_percent': 0,
  'transfer_amount': 0,
  'untransferred_amount': 0,
  'month_bill_plans':[],
  'total_pv':0,
  'actual_pv':0,
  'total_pv_percent':0,
  'seasons_productions':[],
  'month_bill_plans':[],
  recent_bid_projects:[],
  year_completed_projects:[],
  to_complete_projects:[],
  history_untransfer_projects:[],
  'current_contract_count':0,
  'current_contract_amount':0,
  untransferred_percent:0,
  'untransferred_amount':0,
  'end_year_contract_amount':0,
  locations:[],
  typed_projects:[],
  other_projects:[],
  recent_bills:[],
  recent_transfers:[],
  year:2000,
  actual_pv:0,
  total_pv: 0,
  total_pv_percent: 0,
  yearTransData:[],
  yearBillData:[],
  hr_projects:[],
  plan_hrvalue:0,
  hrbill:0,
  actual_hrvalue:0,
  plan_hrcount:0,
  actual_hrcount:0,
  hr_amount:0,
  hr_plan_amount:0,
  hr_percent:0,
  underControlProjects_count:0,
  total_inbuilding_count:0,
  progress_grouped_projects:[],
  total_employee_count:0,
  formal_employee_count:0,
  retired_back_employee_count:0,
  trainee_employee_count:0,
  new_employee_count:0,
  out_employee_count:0,
  age_groupd:{},
  education_grouped:{},
  hr_contract:0
}

const setupStore = (_state, _getters, def) => {

  Object.keys(def).forEach(key => {
    _state[key] = def[key]
    _getters[key] = (state) => state[key]
  })
}

const getters = {
  loading(state) {
    return state.loading
  },
  config(state) {
    return state.config
  },
}

setupStore(state, getters, model)

const actions = {
  getData({
    commit
  }) {
    console.log('getData')
    return new Promise((resolve, reject) => {
      // debounce(()=>{
      commit('startLoading')
      return API.get("public/data/cm?key=nbgz").then(res => {
        commit('saveData', res.data.data)
        setTimeout(()=>{
          actions.getData({commit})
        },1000*60*15)
        resolve()
      }).catch((e) => {
        reject(e)
      }).finally(() => {
        commit('endLoading')
      })
      // },300)
    })
  }

}

const _amount = (a = 0) => parseInt(a / 1000) / 10
const _percent = (a = 0, b) => b ? parseInt(a * 1000 / b) / 10 : '-'
const mutations = {
  startLoading(state) {
    state.loading = true
  },
  endLoading(state) {
    setTimeout(() => {
      state.loading = false
    }, 500)
  },
  saveData(state, data) {
    let config = data
    // 全年计划收款
    
    state.year = moment().year()
    data.hra_projects.forEach((v,i)=>{
        v.index = i+1
        v.amount = (v.amount /10000).toFixed(1)
    })
    Object.keys(model).forEach(key=>{
      state[key] = data[key]
    })
    state.bill_plan_amount = _amount(data.bill_plan_amount)
    state.bill_amount = _amount(data.bill_amount)
    state.bill_percent = _percent(data.bill_amount, data.bill_plan_amount)
    state.transfer_percent = _percent(data.transfer_amount, data.bill_amount)
    state.transfer_amount = _amount(data.transfer_amount)

    if (config.typed_projects) {
      config.typed_projects.sort((a, b) => {
        return types.findIndex(v => v == a.type) > types.findIndex(v => v == b.type) ? 1 : -1
      })
      let total = config.typed_projects.reduce((acc, item) => {
        return acc + item.amount
      }, 0)

      config.typed_projects.forEach(v => {
        v.percent = parseInt(v.amount * 1000 / total) / 10
      })

      state.typed_projects = config.typed_projects
      state.other_projects = config.other_projects
    }

    if (config.recent_bills) {
      config.recent_bills.forEach(v => {
        v.amount = UTIL.formatSalary(v.amount)

        v.billed_at = moment(v.billed_at).fromNow()
      })
      state.recent_bills = config.recent_bills
    }

    if (config.recent_transfers) {
      config.recent_transfers.forEach(v => {
        v.amount = UTIL.formatSalary(v.amount)

        v.billed_at = moment(v.billed_at).fromNow()
      })
      state.recent_transfers = config.recent_transfers
    }

    state.config = config

 
    state.hr_contract = _amount(state.current_contract_amount * 10000 / state.actual_hrcount)

    config.hr_projects.forEach(v => {
      v.amount = UTIL.formatSalary(v.amount)
      v.hrvalue = UTIL.formatSalary(v.hrvalue)
    })
    state.hr_projects = data.hr_projects
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
