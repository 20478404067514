/* @/plugins/api.js
 * author : rackyz
 * date   : 2021/10/21
 * update : 2022/05/27
 * desc   : Enclose 'axios' with HSAPI, to create default api object and binding them to VUE.prototype. You can use 'this.api' to call each service.
 *   2022-05-27: 
 *     1. removed API name style interfaces, replaced with axios original methods to adapt restful API more natrually
 *     2. [store] In vuex/store only need to 
 *        import {API,COS} from '@/plugins/api'
 *     3. [vue] Installed $api,$cos to Vue prototype
 *     4. api methods:
 *        - SetAuthentication: set headers.Authentication to identify user cerification
 *        - SetEnterprise: set headers.Enterprise to identify Enterprise certification
 *        - Clear: clear all user login session state in api state and localStorage
 *        - 
 * 
 *      
 */

import axios from 'axios'
import config from '../config'
let CancelToken = axios.CancelToken
let source = CancelToken.source
import bus from './bus'
var o = {
  config
}

// Axios Server to backends Server
export let API = axios.create({
  baseURL: config.server,
  timeout: 12000
})
export let COS = axios.create({
  baseURL: config.cosServer
})

// default api-version
API.defaults.headers = {
  "api-version": config.apiVersion || "v0"
}


const requestThreads = {}

API.interceptors.request.use(async config => {
  return config
  if (requestThreads[config.url]) {
    let cancel
    config.cancelToken = new axios.CancelToken(function (c) {
      cancel = c
    })
    cancel("repeated:" + config.url)
    return config
  } else {
    if (config.url != '/files' && config.method == 'get') {
      requestThreads[config.url] = setTimeout(() => {
        delete requestThreads[config.url]
      }, 200)
    }

    return config
  }
}, error => Promise.reject(error))

// axios response hooks
API.interceptors.response.use(data => {
  if (data.config && data.config.url) {

    clearTimeout(requestThreads[data.config.url])
    delete requestThreads[data.config.url]
  }

  if (data && data.data && data.data.code === -1) {
    return Promise.reject((data.data.error ? data.data.error : (data.data.message ? data.data.message : '未知')))
  }
  return data;
}, err => {
  if (axios.isCancel(err)) {
    return Promise.reject("repeat:", err.message)
  }
  if (err.message && err.message.includes('timeout')) {
    return Promise.reject('网络连接超时')
  }

  if (err.response) {
    if (err.response.status == 504 || err.response.status == 404) {
      return Promise.reject(`网络连接失败:[${err.response.status}]`)
    } else if (err.response.status == 403) {
      return Promise.reject('403-权限不足,请联系管理员!')
    } else if (err.response.status == 401) {
      API.ClearAll()
      bus.$emit('401')
      return Promise.reject('401-登录状态已过期')
    } else {
      return
    }
  }

  if (err.config && err.config.method == 'options') {
    return Promise.reject('服务器访问失败')
  }
  return Promise.reject(err);
})

// set header.Authorization
API.SetAuthorization = function (token) {
  if (token)
    API.defaults.headers.Authorization = token
}

// set header.Enterprise
API.SetEnterprise = function (ent_id) {
  if (ent_id)
    API.defaults.headers.Enterprise = ent_id
}

// remove header.Enterprise
API.ClearEnterprise = function () {
  delete API.defaults.headers.Enterprise
}

// clear all login session state
API.ClearAll = function () {
  delete API.defaults.headers.Authorization
  delete API.defaults.headers.Enterprise
  localStorage.removeItem('hs-token')
  localStorage.removeItem('current_enterprise')
}

o.install = async (vue) => {
  let token = localStorage.getItem('hs-token')
  if (token) {
    API.SetAuthorization(token)
  }
  vue.prototype.$api = API
  vue.prototype.$cos = COS
}

o.API = API
o.COS = COS


function GetObjectURL(file) {
  var url = null;
  if (window.createObjectURL != undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}

function GetFileName(url) {
  if (url) {
    let fileName = url.replace(/(.*\/)*([^.]+)/i, "$2");
    return fileName;
  }
}

function GetFileExt(url) {
  if (url) {
    let ext = url.substring(url.lastIndexOf(".") + 1);
    return ext;
  }
}


/** upload
 *  params:
 *    files - e.srcElement.files
 *    callback:{
 *       onPreload
 *       onProgress
 *       onSuccess
 *       onFail
 *    }
 *   config:{
 *      
 *   }
 */

COS.upload = async (files, {
  onPreload,
  onProgress,
  onFail,
  onSuccessFile,
  onSuccess
}, {
  uid,
  vdisk
}={}) => {
  if (!files) return;
  let fileObjects = Object.values(files).map((f) => ({
    name: f.name,
    size: f.size,
    ext: GetFileExt(f.name),
    url: GetObjectURL(f),
    percent:0,
    loading: true,
    vdisk: vdisk || "ref",

    cancel() {
      if (this.source)
        this.source.cancel("取消")
    }
  }))

  console.log(fileObjects)

  // 1 - 预加载：将图片文件转换为ObjectURL
  //     此处可以获取当前文件对象的信息
  if (onPreload)
    onPreload(fileObjects)


  // 2 - 上传文件信息到文件服务器，换取文件ID
  API.post('zzl/files', fileObjects).then(res => {
    // 返回文件ID
    let resFiles = res.data.data.files
    let coskey = res.data.data.coskey

    // 3 - 将文件以新ID命名，上传文件到cos
    return Promise.all(
      fileObjects.map((v, i, a) => {
        a[i].loading = true
        a[i].id = resFiles[i].id
        let file = files[i]
        let filekey = "files/" + resFiles[i].url
        file.loading = true
        var formdata = new FormData()
        formdata.append('key', filekey)
        formdata.append('file', files[i])
        fileObjects[i].source = CancelToken.source()

        return COS.post('', formdata, {
          'content-type': 'multipart/form-data',
          headers: {
            Authorization: coskey
          },
          cancelToken: fileObjects[i].source.token,
          onUploadProgress: progressEvent => {
            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
            fileObjects[i].percent = complete

            if (onProgress)
              onProgress(fileObjects[i])
          }
          
        }).then(() => {

          // 3.Success - 单项文件上传完毕
          //    替换链接地址为cos地址
          //    移除控制器和加载状态
        
           API.patch('zzl/files', fileObjects.map(v => ({
             id: v.id,
             state: 1
           })), {
             headers: {
               zzl_id: uid
             }
           }).then(() => {
              a[i].url = COS.defaults.baseURL + filekey
              delete a[i].loading
              delete a[i].percent
              delete fileObjects[i].source
             if (onSuccessFile)
               onSuccessFile(a[i])
           })
        }).catch(e => {
          if (e && e.message == "取消上传") {
            a[i].canceled = true
            return
          }
          return Promise.reject({
            e,
            file
          })
        })
      })
      ).then(() => {
        if (onSuccess){
          onSuccess(fileObjects)
        }
      })
      }).catch((e) => {
        // 3 - if failed, rollback from the file server
         API.patch('zzl/files', fileObjects.map(v => ({
           id: v.id,
           state: 2
         })), {
           headers: {
             zzl_id: uid
           }
         }).then(()=>{
          console.log(e, e.e.message == 'Cancel')
           if (e && e.e && e.e.message == '取消') {
             return
           }
          if (onFail) {
           
            onFail(e)

          }
         })
        
      })
}

export default o
