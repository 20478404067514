<template>
   <dv-border-box-12 class="container ldv-list2" @mouseenter.native="make_pause" @mouseleave.native="restart" >
    <div class="title">{{data.name}}</div>
    <div  ref="container" style="position:absolute;left:10px;top:35px;right:10px;bottom:10px;overflow:hidden;">
      <template v-for="p in current_items">
        <div :key="p.id" class="l-project" :class="{'l-project-ani':p.hide}" :style="`height:${p.hide?0:itemHeight}px`">
          <div class="flex-col align-start" style="flex-shrink:1;flex-grow:0;max-width:70%;">
            <div class="l-project-title">{{p.project_name}}</div>
            <div class="l-project-sub">{{p.detail}}{{p.note}}</div>
          </div>
          <div class="flex-col align-end" style="flex-shrink:0;max-width:80px;">
            <div class="l-amount"> {{p.amount}}</div>
            <div class="l-date"> {{p.billed_at}}</div>
          </div>
        </div>
      </template>
    </div>
  </dv-border-box-12>
</template>

<script>
  export default {
    
     props:{
      data:{
        type:Object,
        default:()=>{}
      },
      pause:{
        type:Boolean
      }
    },
    data(){
      return {
        current_items:[],
        cur_page:0,
        page_size:7,
        paused:false,
        itemHeight:0,
      }
    },
    mounted(){
      this.paused = false
      setTimeout(()=>{
        
      this.getPage()
      this.interval = setInterval( this.getPage,10000)
      },1000)

       this.$nextTick(()=>{
        this.itemHeight = this.$refs.container.clientHeight / this.page_size
      })
    },
    methods:{
      getPage(){
       if(this.paused || this.pause)
          return
        if(this.cur_page >= Math.ceil(this.bills.length - this.page_size + 1))
           this.cur_page = 1 - this.page_size
        if(this.current_items.length == 0){
          this.current_items = this.bills.slice(this.cur_page,this.cur_page + this.page_size).map(v=>({...v,hide:false}))
        }else{
          this.current_items[0].hide = true
          this.current_items.push({...this.bills[this.cur_page+this.page_size],hide:false})
          
        this.cur_page++
          setTimeout(()=>{
            this.current_items.splice(0,1)
          },1000)
        }
      },
      make_pause(){
        this.paused = true
      },
      restart(){
        console.log('out')
        this.paused = false
      }
    },
    computed:{
      bills(){
        return this.data.raw?this.data.raw:[]
      }
    }
  }
</script>


<style lang="less">
.ldv-list2{
  position: relative;
  .title{
    padding:10px 15px;
    color:#3ef;
  }
  .l-project-ani{
    transition:all 0.5s;
  }
  .l-project{
    
    
    background:#ffffff11;
    height:48px;
    width:calc(100% - 10px);
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding:0 5px;
    flex-wrap:nowrap !important;
    .l-project-title{
      max-width:100%;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    color:#ffffff;
    }

    .l-project-sub{
      
      color:rgb(32, 255, 207);
      font-size:10px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      max-width:200px;
      margin-top:2px;
    }
    .l-amount{
      color:#3af;
      font-size:12px;
    }

    .l-date{
      color:#aaa;
      font-size:10px;
      margin-top:2px;
    }
  }
}
</style>