<template>
  <div class="container l-year " >
    
    <dv-decoration-3 style="width:280px;height:40px;margin-right:20px;" />
    <span style="font-weight:bold;font-size:32px;color:#3ef;letter-spacing:1.2px;"><span style="font-weight:normal">{{data.year}}</span>年经营情况</span>
     <dv-decoration-3 style="width:280px;height:40px;margin-left:20px;" />
  </div>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>({})
      }
    }
  }
</script>

<style lang="less" scoped>
.l-year{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif, '黑体';
  font-size:20px;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  line-height:28px;
  color:#3ef;
}
</style>