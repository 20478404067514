<template>
  <div class="container"  style="padding:0 5px;width:calc(100% - 10px)">
      <div style="font-size:16px;color:#3ef;position:absolute;left:10px;top:10px;bottom:10px;right:10px;">
        <div style="padding:5px 0;border-bottom:1px solid #33aaff">
        2023年度产值计划完成情况
        </div>
         <div style="background:#ffffff11;margin-top:10px;padding:0px 10px;padding-bottom:10px;">
        <div class="flex-wrap flex-between">
          <div class="num-board">
            <div class="num-board-label" style="">全年计划产值</div>
            <div class="num-board-value flex-wrap align-end">{{config.year_plan_production}}<div class="num-board-unit">万元</div></div>
          </div>

          <div class="num-board" style="margin-left:40px">
            <div class="num-board-label" style="">全年已完成产值</div>
            <div class="num-board-value flex-wrap align-end">{{config.year_actual_production}}<div class="num-board-unit">万元</div></div>
          </div>
          <div class="num-board" style="margin-left:40px">
            <div class="num-board-label" style="">已完成占比</div>
            <div class="num-board-value flex-wrap align-end" style="color:#33ffaa">{{config.year_production_percent}}<div class="num-board-unit">%</div></div>
          </div>
        </div>
        </div>


       <div style="margin-top:10px;font-size:12px;color:#fff;width:100%;">
          2023年产值计划按季度分布
          </div>
          
          <div style="background:#ffffff11;height:calc(100% - 145px);border-radius:5px;position:relative;">
          <div class="flex-wrap" style="font-size:12px;color:#3af;border-bottom:1px dashed #3af;margin-top:10px;padding:5px 0;">
            <div style="width:20%;text-align:center;">
            季度
            </div>
            <div  style="width:30%;text-align:center;">
            计划产值
            </div>
            <div style="width:30%;text-align:center;">
            已完成产值
            </div>
            <div style="width:20%;text-align:center;">
            完成比例
            </div>
          </div>
          <div class="flex-col flex-line align-stretch" style="height:calc(100% - 30px);overflow:hidden;position:relative;">
          <template v-for="(m,i) in seasons_productions">
          <div class="flex-wrap" style="font-size:20px;color:#3ef;border-bottom:1px dashed #3af;flex-grow:1;height:25%;flex-shrink:1" :key="m.id" :style="i<moment().quarter()?`border-right:2px solid #33ffaa`:''">
             <div style="width:20%;text-align:center;font-size:12px;color:#fff;">
            {{m.name}}
            </div>
            <div  style="width:30%;font-family:arial;font-size:20px;text-align:right">
            {{(m.plan_amount / 10000).toFixed(1)}}<span style="font-size:12px;margin-left:5px;margin-right:20px;"><span style="color:#ffffffaa">万元</span></span>
            </div>
            <div  style="width:30%;font-family:arial;font-size:20px;text-align:right" :style="m.amount?'color:#3fa':'color:#ffffffaa'">
            {{(m.amount / 10000).toFixed(1)}}<span style="font-size:12px;margin-left:5px;margin-right:20px;"><span style="color:#ffffffaa">万元</span></span>
            </div>
            <div  style="width:20%;font-family:arial;font-size:20px;text-align:right" :style="m.percent >= 90?'color:#3fa':(m.percent < 1?'color:#ffffffaa':'')">
            {{(m.percent).toFixed(1)}}<span style="font-size:12px;margin-left:5px;margin-right:20px;"><span style="color:#ffffffaa">%</span></span>
            </div>
          </div>
          </template>
        </div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
  
import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters('board',['config','total_pv','actual_pv','seasons_productions','total_pv_percent'])
    }
  }
</script>

<style lang="less" scoped>
.num-board{
  display:flex;
  flex-direction: column;
  .num-board-label{
    font-size:12px;color:#fff;margin-top:10px;
  }

  .num-board-value{
    font-family:impact;font-size:30px;
    .num-board-unit{
      margin-left:5px;font-size:14px;
    }
  }
}
</style>