<template>
  <dv-border-box-8 :dur="0" class="container ldv-chart" >
        <div class="title flex-wrap flex-center" style="position:relative;bottom:-6px">{{data.name}} (合同余额:万元)</div>
        <div class="flex-wrap flex-center flex-around" style="background:#ffffff11;margin:0 15px;height:160px;overflow:hidden;">
          <dv-conical-column-chart :config="config2" style="height:130px;" /> 
         
        </div>
        <div style="padding:10px 15px;position:relative;height:calc(100% - 225px);overflow:hidden;">
          
         <dv-scroll-board :config="config3" style="height:100%;height:100%" />
        </div>
  </dv-border-box-8>
</template>

<script>
  import UTIL from '@/utils'
  export default {
     props:{
      data:{
        type:Object,
        default:()=>{}
      }
    },
    computed:{
      
        config(){
          return {
          lineWidth: 12,
           waitTime:5000,
           data: this.data.raw?this.data.raw.map(v=>({
            name:v.type,
            value:v.count
           })):[]
          }
        },
        config2(){
          return {
          lineWidth: 12,
          showValue:true,
           waitTime:5000,
           img:['https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/billboard/Building-06.png'],
           imgSideLength:20,
           data:this.data.raw? this.data.raw.map(v=>({
            name:v.type,
            value:parseInt(v.remain_amount / 10000)
           })):[]
        }},
        config3(){
          let list = this.data.raw || []
        
          let data = [...list]
          data.sort((a,b)=>a.remain_amount > b.remain_amount?-1:1)
         
          return {
          header: ['事业部', '在建项目', '合同余额','今年新签'],
          columnWidth: [160,100,120,120],
          
          waitTime:10000,
          hoverPause:true,
          carousel:"page",
          headerBGC:"#ffffff22",
          oddRowBGC:"#ddffee33",
          evenRowBGC:"#ddeeff55",
          align:["left","right","right","right"],
          rowNum:3,data:data.map(v=>([`<span style='color:#3ef;font-weight:bold;'>${v.type.slice(0,4)}</span>`,v.count,v.remain_amount?UTIL.formatSalary(v.remain_amount):'-',v.sign_amount?UTIL.formatSalary(v.sign_amount):'-']))
      }
      }
    }
  }
</script>
<style lang="less">
.ldv-chart{
  position: relative;
  .title{
    padding:10px 15px;
    color:#3ef;
  }

  .active-ring-name{
    font-size:16px !important;
  }
}
</style>