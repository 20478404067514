<template>
  <dv-border-box-12 class="container l-numboard-progress">
    <div class="caption">{{data.name}}</div>
     <div class="value"> <span style="color:#3fa">{{data.value}}</span>
    <span class="unit" v-if="data.unit">{{data.unit}}</span></div>
    <div class="percent">{{data.percent}}%</div>
    <dv-percent-pond :config="config2" style="height:20px;position:absolute;bottom:20px;left:20px;right:20px;" />
    <span style="font-size:12px;color:#3fa;position:absolute;left:20px;bottom:67px;"><span style="color:#aaa">共</span> {{data.total}}<span style="font-size:12px;margin-left:2px;color:#aaa;">{{data.total_unit}}</span></span> 
  </dv-border-box-12>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>{}
      }
    },
    computed:{
      config(){
        return {
          number:[this.data.value],
          toFixed:this.data.toFixed,
          textAlign:"right",
          content:'{nt}'
        }
      },
      config2(){
        return {
          value: this.data.percent || 0,
          localGradient: true,
           borderWidth: 2,
  borderRadius: 2,
  textColor:"#00000000",
  borderGap: 2
        }
      }
    }
  }
</script>

<style lang="less">
.l-numboard-progress{
  .caption{
    position: absolute;
    left:20px;
    top:15px;
    color:#3ef;
    font-size:14px;
  }

  .value{
    position: absolute;
    bottom:48px;
    right:20px;
    color:#3ef;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size:40px;
    font-family: impact;
  }

  .percent{
    position: absolute;
    color:#fff;
    left:20px;
    bottom:45px;
    font-size:12px;
  }

  .unit{
     color:#aaa;
      font-size:16px;
      font-weight: bold;
    
  }

  svg{
    text{
      font-size:12px;
    }
  }
}
</style>